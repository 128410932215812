<template>
    <div class="icon-person-circle">
        <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
            <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
        </svg>
        <span>{{ props.title }}</span>
    </div>
</template>

<script setup lang="ts">
    const props = defineProps({
            title: { type: String, default: ''}
        })
</script>

<style>
.icon-person-circle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    color: rgb(232 226 224);
}

.icon-person-circle:hover {
    color: white;
    cursor: pointer;
}
</style>